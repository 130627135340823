import React from 'react';
import './loadingGuage.css';

const LoadingGuage = ({guage,full,cancel}) => (
  <div className="page-full loading loading-guage">
    <div className="center">
      カタログのプリントを作成中...
      <div className="guage">
        { guage } / { full }
      </div>
      <button className="btn-cancel btn btn-info" onClick={cancel}>
        キャンセル
      </button>
    </div>
  </div>
)

export default LoadingGuage;
