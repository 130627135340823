import React from 'react';
import loadingImg from '../../assets/loading.gif';
import './loadingPage.css';

const LoadingPage = () => (
  <div className="page-full loading loading-page">
    <div className="center">
      <p className="text-loading">カタログをローディング中...</p>
      <img
        className="img-loading"
        src={loadingImg}
        alt="loading..."/>
    </div>
  </div>
)

export default LoadingPage
