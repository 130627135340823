export const colorDict = [
{ id: "AM", name: "アンバー" },
{ id: "BE", name: "ベージュ" },
{ id: "BK", name: "ブラック" },
{ id: "BL", name: "ブルー" },
{ id: "BR", name: "ブラウン" },
{ id: "BZ", name: "ブロンズ" },
{ id: "CF", name: "カラフル" },
{ id: "CL", name: "クリアー" },
{ id: "CM", name: "キャメル" },
{ id: "CP", name: "コッパー" },
{ id: "CR", name: "クリーム" },
{ id: "DB", name: "ダークブルー" },
{ id: "DG", name: "ダークグレー" },
{ id: "DK", name: "ダーク" },
{ id: "DW", name: "ダークブラウン" },
{ id: "GL", name: "ゴールド" },
{ id: "GN", name: "グリーン" },
{ id: "GY", name: "グレイ" },
{ id: "IN", name: "インディゴブルー" },
{ id: "IV", name: "アイボリー" },
{ id: "KH", name: "カーキ" },
{ id: "LA", name: "ラベンダー" },
{ id: "LB", name: "ライトブルー" },
{ id: "LG", name: "ライトグレー" },
{ id: "LW", name: "ライトブラウン" },
{ id: "MA", name: "マルーン" },
{ id: "MG", name: "ミントグリーン" },
{ id: "MT", name: "マスタード" },
{ id: "MX", name: "マルチカラー，ミックス" },
{ id: "NT", name: "ナチュラル" },
{ id: "NV", name: "ネイビーブルー" },
{ id: "OL", name: "オリーブ" },
{ id: "OR", name: "オレンジ" },
{ id: "PE", name: "ピーチ" },
{ id: "PK", name: "ピンク" },
{ id: "PU", name: "パープル" },
{ id: "RB", name: "ロイヤルブルー" },
{ id: "RD", name: "レッド" },
{ id: "RS", name: "ローズ" },
{ id: "SL", name: "シルバー" },
{ id: "TQ", name: "ターコイズ" },
{ id: "WH", name: "ホワイト" },
{ id: "WN", name: "ワイン" },
{ id: "YL", name: "イエロー" },
];


