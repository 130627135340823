import axios from "axios";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { history } from "../utility/store.js";

import Home from "./home";
import { queryCheck } from "../utility/utility";
import Catalogue from "./catalogue";
import Guideline from "../components/guideline";
import { getParameterByName } from "../utility/utility";
// import Comida from './comida'
import "./app.css";
import { CATALOGUE_PASS } from "../modules/static/api";

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
const env = process.env.NODE_ENV;
let tokenCount = 0;
export default class App extends Component {
  render() {
    if (window.location.href.indexOf("code=") !== -1) {
      if (tokenCount === 0) {
        tokenCount++;
        const code = getParameterByName("code");
        let accessedBrandName = localStorage.getItem("catalogType");
        history.push(
          `/${accessedBrandName}/catalogue?password=023109238172819&code=${code}`
        );
      }
    }
    return (
      <div>
        {!queryCheck("password", CATALOGUE_PASS) && env !== "development" ? (
          <p className="message-center"> 無効なパスワードです。 </p>
        ) : (
          <main>
            <Route exact path="/" component={Home} />
            <Route exact path="/:brand/catalogue" component={Catalogue} />
            <Route exact path="/guideline" component={Guideline} />
          </main>
        )}
      </div>
    );
  }
}
