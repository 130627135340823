import React, { Component } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
// import CurrencyInput from 'react-currency-input';
import "./navi.css";

//[TODO] this should be an container with a bunch of components
class Navi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceMin: this.props.filters.priceRange.min,
      priceMax: this.props.filters.priceRange.max,
      seasons: this.props.filters.seasons,
      typing: false,
      typingTimeout: 0,
      hasUploadedFile: false,
      options: [
        { name: "オール:通年出荷可能", id: "オールシーズン" },
        { name: "SS:2～8月出荷可能", id: "SS" },
        { name: "AW:9～1月出荷可能", id: "AW" },
      ],
      typeOfStocks: [
        { name: "有効数ありだけの商品を表示", value: "effective" },
        { name: "有効数ない商品を表示", value: "notEffective" },
      ],
    };

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.cancelUploadedFile = this.cancelUploadedFile.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.changeTextValue = this.changeTextValue.bind(this);
    this.activateMobileSearch = this.activateMobileSearch.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeValuation = this.changeValuation.bind(this);
    this.changeBrand = this.changeBrand.bind(this);
    this.adjustPriceByRate = this.adjustPriceByRate.bind(this);
    this.onMultiChange = this.onMultiChange.bind(this);
    this.hideFilter = this.props.hideFilter;
    this.showHideStockBtn = this.props.showHideStockBtn;
    this.showHideStockTypeBtn = this.props.showHideStockTypeBtn;
    this.hideStockInfo = this.props.hideStockInfo;
  }

  onMultiChange(selectedList, selectedVal) {
    let selectedListArray = selectedList.map((d) => d.id);
    this.props.filterDataFn({ value: selectedListArray, type: "seasons" });
    setTimeout(function() {
      if ($(".chip").length) {
        $(".chip").each(function(index, chip) {
          var val = $(chip)
            .contents()
            .first()[0]
            .textContent.split(":")[0];
          $(chip)
            .contents()
            .first()[0].textContent = val;
        });
      }
      // search_input
      if ($(".chip").length === 3) {
        $("#search_input").hide();
      } else {
        $("#search_input").show();
      }
    }, 0);
  }

  selectOption(event, type) {
    let target = event.currentTarget ? event.currentTarget : event.srcElement;
    let value = target.value;
    this.props.filterDataFn({ value, type });
  }

  changeName(event) {
    let self = this;
    let target = event.currentTarget ? event.currentTarget : event.srcElement;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      typing: false,
      typingTimeout: setTimeout(function() {
        self.props.filterDataFn({ value: target.value, type: "query" });
      }, 500),
    });
  }

  adjustPriceByRate(event) {
    let self = this;
    let target = event.currentTarget ? event.currentTarget : event.srcElement;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      typing: false,
      typingTimeout: setTimeout(function() {
        self.props.adjustDataFn({
          value: target.value,
          type: "price",
        });
      }, 500),
    });
  }

  changeBrand(event) {
    let self = this;
    let target = event.currentTarget ? event.currentTarget : event.srcElement;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      typing: false,
      typingTimeout: setTimeout(function() {
        self.props.filterDataFn({ value: target.value, type: "brand" });
      }, 500),
    });
  }

  changeValuation(event) {
    let self = this;
    let target = event.currentTarget ? event.currentTarget : event.srcElement;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      typing: false,
      typingTimeout: setTimeout(function() {
        self.props.filterDataFn({ value: target.value, type: "valuation" });
      }, 500),
    });
  }

  changeTextValue(event, type) {
    let target = event.currentTarget ? event.currentTarget : event.srcElement;
    let value = !target.value ? 0 : target.value;
    if (type === "price-min") {
      this.setState({ priceMin: value });
    } else {
      this.setState({ priceMax: value });
    }
    this.props.filterDataFn({ value, type });
  }

  activateMobileSearch(event) {
    let navi = document.getElementById("navigation");
    let mobileSearchActiveClass = "search-active";
    let re = new RegExp(mobileSearchActiveClass, "g");
    let target = event.currentTarget ? event.currentTarget : event.srcElement;
    if (navi.className.indexOf(mobileSearchActiveClass) > -1) {
      navi.className = navi.className.replace(re, "");
      target.innerHTML = "<i className='fa fa-search'></i>検索する";
    } else {
      navi.className += ` ${mobileSearchActiveClass}`;
      target.innerHTML = "<i className='fa fa-close'></i>閉じる";
    }
  }

  cancelUploadedFile() {
    document.getElementById("input-csv-uploader").value = "";
    this.props.filterDataFn({ value: [], type: "csv" });
    this.setState({ hasUploadedFile: false });
  }

  handleFileUpload(event) {
    this.setState({ hasUploadedFile: true });
    const file = event.target.files[0];
    const reader = new FileReader();
    if (!file) return;
    reader.onload = (e) => {
      const csvData = e.target.result;
      const dataArray = csvData
        .replaceAll("\r", "")
        .split("\n")
        .map((row) => row.split(","));
      const firstColumnValues = dataArray.map((row) => row[0]).slice(1);
      this.props.filterDataFn({ value: firstColumnValues, type: "csv" });
    };

    reader.readAsText(file);
  }

  fixImageLoading() {
    const url =
      "https://drive.google.com/uc?export=view&id=1hn0qhjBhCq3gRSMaxA2bif1njs8hWIpw";
    window.open(url, "_blank").blur();
    window.location.reload();
  }

  render() {
    return (
      <nav hidden={this.hideFilter} id="navigation" className="header-nav">
        <button
          className="btn-mobile-search btn"
          onClick={this.activateMobileSearch}
        >
          検索する
        </button>
        <div className="container-navi container clearfix">
          <div className="nav-search">
            <div className="input-group">
              <div className="input-group-btn">
                {this.state.hasUploadedFile ? (
                  <button
                    className="btn btn-default"
                    onClick={this.cancelUploadedFile}
                  >
                    cancel
                  </button>
                ) : (
                  <button
                    className="btn btn-default"
                    onClick={() =>
                      document.getElementById("input-csv-uploader").click()
                    }
                  >
                    CSV
                    <i className="fa fa-upload" />
                  </button>
                )}

                <input
                  id="input-csv-uploader"
                  hidden
                  className="btn btn-default"
                  type="file"
                  accept=".csv"
                  onChange={(event) => this.handleFileUpload(event)}
                />
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="商品名・コードで検索..."
                onChange={this.changeName}
              />
            </div>
          </div>
          <div className="nav-filters">
            <div className="nav-filter_season season">
              <Multiselect
                options={this.state.options} // Options to display in the dropdown
                selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                onSelect={this.onMultiChange} // Function will trigger on select event
                onRemove={this.onMultiChange} // Function will trigger on remove event
                placeholder="シーズン"
                // hidePlaceholder={ true }
                displayValue="name" // Property name to display in the dropdown options
              />
            </div>
            <div className="nav-filter_cartegory category">
              <select
                className="custom-select"
                onChange={(event) => this.selectOption(event, "category")}
              >
                <option defaultValue value="">
                  カテゴリー
                </option>
                {this.props.categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div className="nav-filter_cartegory price">
              <div className="input-range">
                <div className="input-price-box">
                  <label className="input-price-tag">¥</label>
                  <input
                    type="number"
                    value={this.state.priceMin}
                    min="0"
                    step="100"
                    className="input-price form-control"
                    placeholder="min price"
                    onChange={(event) =>
                      this.changeTextValue(event, "price-min")
                    }
                  />
                </div>
                <label className="input-range-inbetween"> ~ </label>
                <div className="input-price-box">
                  <label className="input-price-tag">¥</label>
                  <input
                    type="text"
                    value={this.state.valuation}
                    className="input-price form-control"
                    onChange={(event) =>
                      this.changeTextValue(event, "price-max")
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className={`custom-text-field custom-text-wider-field custom-brand ${
                this.showHideStockTypeBtn ? "showElem" : ""
              }`}
            >
              <input
                type="text"
                className="input-text-width form-control"
                placeholder="ブランド"
                onChange={this.changeBrand}
              />
            </div>
            <div
              className={`custom-text-field custom-evaluation ${
                this.showHideStockTypeBtn ? "showElem" : ""
              }`}
            >
              <input
                type="text"
                className="input-text-width form-control"
                placeholder="評価"
                onChange={this.changeValuation}
              />
            </div>
            <div
              className={`custom-text-field custom-price-adjustor ${
                this.showHideStockTypeBtn ? "showElem" : ""
              } `}
            >
              <input
                type="number"
                className="form-control"
                placeholder="$率"
                onChange={this.adjustPriceByRate}
              />
            </div>
          </div>
        </div>
        <div className="container-navi container clearfix">
          <select
            className={`custom-select stock-type ${
              this.showHideStockTypeBtn ? "showElem" : ""
            }`}
            onChange={(event) => this.selectOption(event, "typeOfStocks")}
          >
            <option defaultValue value="">
              在庫有無
            </option>
            {this.state.typeOfStocks.map((stockType) => (
              <option key={stockType.name} value={stockType.value}>
                {stockType.name}
              </option>
            ))}
          </select>
          <button
            className={`btn btn-default btn-stock btn-clear ${
              this.showHideStockBtn ? "showElem" : ""
            }`}
            onClick={() => this.hideStockInfo()}
          >
            {this.props.hideStock ? "在庫数を表示" : "在庫数を非表示"}
          </button>
          <button
            onClick={this.props.selectAllFilteredItemFn}
            className="btn btn-default btn-clear"
          >
            <span>フィルタされた商品全選択</span>
          </button>
          {this.props.selectedItemLength ? (
            <button
              onClick={() => {
                if (this.props.viewMode) this.props.toggleViewMode();
                this.props.clearSelectedItemFn();
              }}
              className="btn btn-default btn-clear"
            >
              <span>選択をクリア</span>
            </button>
          ) : (
            ""
          )}

          <button
            onClick={this.props.toggleViewMode}
            className="btn btn-default btn-clear"
          >
            <span className="btn-wrapper">
              {this.props.viewMode ? (
                <i className="fa fa-eye-slash" />
              ) : (
                <i className="fa fa-eye" />
              )}
            </span>
          </button>
          <button
            onClick={() => this.props.printFn()}
            className="btn btn-default btn-print"
          >
            <i className="fa fa-print" />
            {this.props.selectedItemLength ? (
              <span className="badge">{this.props.selectedItemLength}</span>
            ) : (
              ""
            )}
          </button>
          <button
            onClick={() => this.props.csvFn()}
            className="btn btn-default btn-csv"
          >
            CSV
            {this.props.selectedItemLength ? (
              <span className="badge">{this.props.selectedItemLength}</span>
            ) : (
              ""
            )}
          </button>
        </div>
      </nav>
    );
  }
}
export default Navi;
