import axios from "axios";
import { colorDict } from "./static/dictionary.js";
import { PRODUCT_API_URL } from "./static/api.js";
import { extractValue, getParameterByName } from "../utility/utility";

let pageWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

let numOfLoadingImgs = 24;

if (pageWidth < 576) {
  numOfLoadingImgs = 6;
}

//[TODO] clone the variable
const defaultFilters = {
  priceRange: {
    min: 0,
    max: 100000,
  },
  category: "",
  query: "",
  seasons: [],
};

const initialState = {
  allProducts: [],
  filteredProducts: [],
  selectedProductsForPrint: [],
  selectedBreakPointForPrint: [],
  selectedSmallerText: [],
  productsInCart: [],
  filteredProductLength: 0,
  loadedProducts: [],
  colors: colorDict,
  categories: [],
  customHeader: {
    type: "",
    title: "",
    titleColor: "",
    period: "",
    periodColor: "",
    year: "",
    yearColor: "",
    bgColor: "",
    optionalTitle: "",
    accentColor: "",
  },
  maintenanceMode: false,
  sortMethod: "recommended",
  filters: {
    csv: [],
    priceRange: {
      min: 0,
      max: 100000,
    },
    category: getParameterByName("category"),
    seasons: [],
    typeOfStocks: "",
    valuation: "",
    brand: "",
    query: "",
  },
};

const filterData = (data, filters) => {
  let filteredData = data.filter((product) => {
    let flag = true;

    if (filters["csv"].length > 0) {
      flag =
        filters["csv"].indexOf(product["productId"]) !== -1 ||
        filters["csv"].indexOf(product["productId"] + product["branchId"]) !==
          -1;
    }
    if (product["category"] !== filters["category"] && !!filters["category"]) {
      flag = false;
    }

    if (
      filters["priceRange"].min > product["price"] ||
      filters["priceRange"].max < product["price"]
    ) {
      flag = false;
    }

    if (
      filters["seasons"].length !== 0 &&
      filters["seasons"].indexOf(product["season"]) === -1
    ) {
      flag = false;
    }

    if (
      (filters["typeOfStocks"] === "effective" && product["stocks"] <= 0) ||
      (filters["typeOfStocks"] === "notEffective" && product["stocks"] > 0)
    ) {
      flag = false;
    }

    let queryTargetValue =
      product["name"] +
      product["productId"] +
      product["branchId"] +
      product["material"] +
      product["currentCatalog"] +
      product["firstCatalog"];
    if (queryTargetValue.indexOf(filters["query"]) === -1) {
      flag = false;
    }

    if (
      !!filters["valuation"] &&
      (filters["valuation"].indexOf(product["valuation"]) === -1 ||
        !product["valuation"])
    ) {
      flag = false;
    }

    if (
      !!product["brand"] &&
      product["brand"].indexOf(filters["brand"]) === -1
    ) {
      flag = false;
    }

    return flag;
  });

  if (filters["csv"].length > 0) {
    filteredData = filteredData.sort((a, b) => {
      const aIndex =
        filters["csv"].indexOf(String(a["productId"])) ||
        filters["csv"].indexOf(String(a["productId"]) + String(a["branchId"]));
      const bIndex =
        filters["csv"].indexOf(String(b["productId"])) ||
        filters["csv"].indexOf(String(b["productId"]) + String(b["branchId"]));
      return aIndex - bIndex;
    });
  }

  return filteredData;
};

const sortItemList = (data, method) => {
  if (method === "price-high") {
    return data.sort((a, b) =>
      a.price < b.price ? 1 : b.price < a.price ? -1 : 0
    );
  } else if (method === "stock-high") {
    return data.sort((a, b) =>
      parseInt(a.stocks) < parseInt(b.stocks)
        ? 1
        : parseInt(b.stocks) < parseInt(a.stocks)
        ? -1
        : 0
    );
  } else if (method === "stock-low") {
    return data.sort((a, b) =>
      parseInt(a.stocks) > parseInt(b.stocks)
        ? 1
        : parseInt(b.stocks) > parseInt(a.stocks)
        ? -1
        : 0
    );
  } else {
    return data.sort((a, b) =>
      a.price > b.price ? 1 : b.price > a.price ? -1 : 0
    );
  }
};

export default (state = initialState, action) => {
  let filteredProducts;
  let filters = state.filters;
  let customHeader = state.customHeader;
  let sortedData;

  switch (action.type) {
    case GET_PRODUCT_DATA_FROM_API:
      let categories = extractValue(action.allProducts, "category");
      let season = extractValue(action.allProducts, "season");
      const allProducts = !action.filterChildren
        ? action.allProducts
        : action.allProducts
            .map(function(d) {
              let parentIndex = action.allProducts.findIndex(function(g) {
                return g.productId === d.parentId;
              });
              d.isChild = false;
              if (parentIndex > -1) {
                if (!action.allProducts[parentIndex].children) {
                  action.allProducts[parentIndex].children = [];
                }
                action.allProducts[parentIndex].pageUrl = action.allProducts[
                  parentIndex
                ].pageUrl
                  ? action.allProducts[parentIndex].pageUrl
                  : d.pageUrl;
                action.allProducts[parentIndex].imgUrl = action.allProducts[
                  parentIndex
                ].imgUrl
                  ? action.allProducts[parentIndex].imgUrl
                  : d.imgUrl;
                action.allProducts[parentIndex].bigImgUrl = action.allProducts[
                  parentIndex
                ].bigImgUrl
                  ? action.allProducts[parentIndex].imgUrl
                  : d.bigImgUrl;
                action.allProducts[parentIndex].children.push({
                  id: d.productId,
                  unitSize: d.unitSize,
                  price: d.price,
                  color: d.branchId,
                  pattern: d.pattern,
                  size: d.size,
                  name: d.name,
                  ct: d.ct,
                  info: d.info,
                  material: d.material,
                  memo: null,
                  catalogUnit: d.catalogUnit,
                  deadline: d.deadline,
                });
                d.isChild = true;
              }
              return d;
            })
            .map(function(d) {
              if (d.productId.indexOf("OYA") > -1) {
                d.productId = d.productId.replace("OYA", "");
              }
              if (!!d.children) {
                if (d.children[0].pattern) {
                  d.children = d.children.sort(function(a, b) {
                    if (a.pattern < b.pattern) return -1;
                    if (a.pattern > b.pattern) return 1;
                    return 0;
                  });
                } else {
                  d.children = d.children.sort(function(a, b) {
                    if (parseInt(a.id) < parseInt(b.id)) return -1;
                    if (parseInt(a.id) > parseInt(b.id)) return 1;
                    return 0;
                  });
                }
              }

              return d;
            })
            .sort(function(a, b) {
              let aWholePage = a.currentCatalog
                .replaceAll(/[a-zA-Z]/g, "/")
                .split("/");
              let aPage = parseInt(
                aWholePage[aWholePage.length - 1].split("-")[0]
              );
              let bWholePage = b.currentCatalog
                .replaceAll(/[a-zA-Z]/g, "/")
                .split("/");
              let bPage = parseInt(
                bWholePage[bWholePage.length - 1].split("-")[0]
              );
              if (isNaN(aPage) || isNaN(bPage)) {
                return 0;
              }
              if (aPage < bPage) return -1;
              if (aPage > bPage) return 1;
              return 0;
            })
            .filter(function(d) {
              return !d.isChild;
            });

      filteredProducts = filterData(Object.assign([], allProducts), filters);

      if (filteredProducts[0]["customHeaderType"] !== "") {
        customHeader = {
          type: filteredProducts[0]["customHeaderType"],
          title: filteredProducts[0]["customHeaderTitle"],
          titleColor: filteredProducts[0]["customHeaderTitleColor"],
          period: filteredProducts[0]["customHeaderSeason"],
          periodColor: filteredProducts[0]["customHeaderSeasonColor"],
          year: filteredProducts[0]["customHeaderYear"],
          yearColor: filteredProducts[0]["customHeaderYearColor"],
          accentColor: filteredProducts[0]["customHeaderAccentColor"],
          optionalTitle: filteredProducts[0]["customHeaderOptionalTitle"],
          bgColor: filteredProducts[0]["customHeaderBgColor"],
        };
      }

      return Object.assign({}, state, {
        allProducts: allProducts,
        filteredProducts: filteredProducts,
        filteredProductLength: filteredProducts.length,
        loadedProducts: filteredProducts.slice(0, numOfLoadingImgs),
        categories: categories,
        season: season,
        customHeader: customHeader,
        maintenanceMode: filteredProducts[0]["maintenance"] ? true : false,
      });

    case UPDATE_PRODUCT_DATA:
      const adjustData = (products) => {
        if (action.params.type === "price") {
          return products.map((product) => {
            product.adjustedPrice = product.price * action.params.value;
            return product;
          });
        } else if (action.params.type === "imgUrl") {
          // find product by productId and branchId and change its imgUrl with action.params.value.imgUrl
          return products.map((product) => {
            if (
              product.productId === action.params.value.productId &&
              product.branchId === action.params.value.branchId
            ) {
              product.imgUrl = action.params.value.imgUrl;
            }
            return product;
          });
        } else {
          return products;
        }
      };
      return {
        ...state,
        allProducts: adjustData(state.allProducts),
        filteredProducts: adjustData(state.filteredProducts),
        loadedProducts: adjustData(state.loadedProducts),
      };

    case UPDATE_FILTERED_PRODUCTS:
      //[TODO] refactor
      let type = action.params.type;
      if ("price-min" === type) {
        filters.priceRange.min = action.params.value;
      }
      if ("price-max" === type) {
        filters.priceRange.max = action.params.value;
      }
      if (type.indexOf("price") === -1 || "csv" === type) {
        filters[type] = action.params.value;
      }

      filteredProducts = filterData(state.allProducts, filters);

      if (state.sortMethod !== "recommended") {
        filteredProducts = sortItemList(filteredProducts, state.sortMethod);
      }

      return {
        ...state,
        loadedProducts: [...filteredProducts.slice(0, numOfLoadingImgs)],
        filteredProducts: filteredProducts,
        filteredProductLength: filteredProducts.length,
        filters,
      };

    case SELECT_BREAKPOINT_FOR_PRINT:
      return {
        ...state,
        selectedBreakPointForPrint: [
          ...state.selectedBreakPointForPrint,
          action.item,
        ],
      };

    case SELECT_SMALLER_TEXT:
      return {
        ...state,
        selectedSmallerText: [...state.selectedSmallerText, action.item],
      };

    case DESELECT_SMALLER_TEXT:
      const smallerTexts = state.selectedSmallerText.filter((p) => {
        return (
          p.productId + p.branchId !==
          action.item.productId + action.item.branchId
        );
      });
      return {
        ...state,
        selectedSmallerText: smallerTexts,
      };

    case DESELECT_BREAKPOINT_FOR_PRINT:
      const points = state.selectedBreakPointForPrint.filter((p) => {
        return (
          p.productId + p.branchId !==
          action.item.productId + action.item.branchId
        );
      });
      return {
        ...state,
        selectedBreakPointForPrint: points,
      };

    case SELECT_PRODUCTS_FOR_PRINT:
      return {
        ...state,
        selectedProductsForPrint: [
          ...state.selectedProductsForPrint,
          action.item,
        ],
      };

    case HANDLE_TEXT_CHANGE:
      let updatedSelectedProducts = state.selectedProductsForPrint;
      if (
        state.selectedProductsForPrint.some(
          (p) => p.productId === action.item.productId
        )
      ) {
        updatedSelectedProducts = state.selectedProductsForPrint.map((p) => {
          if (p.productId === action.item.productId) {
            return action.item;
          }
          return p;
        });
      }
      return {
        ...state,
        selectedProductsForPrint: updatedSelectedProducts,
      };

    case CHANGE_SELECTED_CARD_POSITION:
      let repositionedSelectedProducts = [...state.selectedProductsForPrint];
      // change the position of the action.item in the selectedProductsForPrint array.
      // If the action.positionDir is 'up', then move the item up by one position, if 'down', then move the item down by one position.
      const currentIndex = state.selectedProductsForPrint.findIndex(
        (p) =>
          p.productId + p.branchId ===
          action.item.productId + action.item.branchId
      );
      if (currentIndex > -1) {
        const newPosition =
          action.positionDir === "up" ? currentIndex - 1 : currentIndex + 1;
        if (
          newPosition >= 0 &&
          newPosition < state.selectedProductsForPrint.length
        ) {
          const [movedItem] = repositionedSelectedProducts.splice(
            currentIndex,
            1
          );
          repositionedSelectedProducts.splice(newPosition, 0, movedItem);
        }
      }

      return {
        ...state,
        selectedProductsForPrint: repositionedSelectedProducts,
      };

    case DESELECT_PRODUCTS_FOR_PRINT:
      const items = state.selectedProductsForPrint.filter((p) => {
        return (
          p.productId + p.branchId !==
          action.item.productId + action.item.branchId
        );
      });
      return {
        ...state,
        selectedProductsForPrint: items,
      };

    case PUT_IN_CART:
      return {
        ...state,
        productsInCart: [...state.productsInCart, action.item],
      };

    case CLEAR_SELECTIONS:
      return {
        ...state,
        selectedProductsForPrint: [],
        selectedBreakPointForPrint: [],
        selectedSmallerText: [],
      };

    case ALL_SELECTIONS:
      const combinedSelections = [
        ...state.selectedProductsForPrint,
        ...state.filteredProducts.filter(
          (p) =>
            !state.selectedProductsForPrint.some(
              (s) => s.productId + s.branchId === p.productId + p.branchId
            )
        ),
      ];
      return {
        ...state,
        selectedProductsForPrint: combinedSelections,
      };

    case SORT_DATA:
      if (action.method === "recommended") {
        sortedData = filterData(state.allProducts, filters);
      } else {
        sortedData = sortItemList(state.filteredProducts, action.method);
      }

      return {
        ...state,
        selectedProductsForPrint: [],
        filteredProducts: sortedData,
        loadedProducts: sortedData.slice(0, numOfLoadingImgs),
        sortMethod: action.method,
      };

    case CLEAR_SEARCH_FILTERS:
      filteredProducts = filterData(state.allProducts, defaultFilters);

      return Object.assign({}, state, {
        filteredProducts: filteredProducts,
        filteredProductLength: filteredProducts.length,
        loadedProducts: filteredProducts.slice(0, numOfLoadingImgs),
        filers: defaultFilters,
      });

    case LOAD_MORE_PRODUCTS:
      let currentBatch = state.loadedProducts.length;
      let nextBatch = state.filteredProducts.slice(
        currentBatch,
        currentBatch + numOfLoadingImgs
      );
      return {
        ...state,
        loadedProducts: state.loadedProducts.concat(nextBatch),
      };

    case LOAD_ALL_PRODUCTS:
      return {
        ...state,
        loadedProducts: state.filteredProducts,
      };
    default:
      return state;
  }
};

export const loadMoreProducts = () => {
  return (dispatch) => {
    dispatch({
      type: LOAD_MORE_PRODUCTS,
    });
  };
};
export const selectBreakPointForPrint = (item) => {
  return (dispatch) => {
    dispatch({
      type: SELECT_BREAKPOINT_FOR_PRINT,
      item,
    });
  };
};

export const deselectBreakPointForPrint = (item) => {
  return (dispatch) => {
    dispatch({
      type: DESELECT_BREAKPOINT_FOR_PRINT,
      item,
    });
  };
};

export const selectSmallerText = (item) => {
  return (dispatch) => {
    dispatch({
      type: SELECT_SMALLER_TEXT,
      item,
    });
  };
};

export const deselectSmallerText = (item) => {
  return (dispatch) => {
    dispatch({
      type: DESELECT_SMALLER_TEXT,
      item,
    });
  };
};

export const selectProductsForPrint = (item) => {
  return (dispatch) => {
    dispatch({
      type: SELECT_PRODUCTS_FOR_PRINT,
      item,
    });
  };
};

export const handleTextChange = (item, textType, newText) => {
  item[textType] = newText;
  return (dispatch) => {
    dispatch({
      type: HANDLE_TEXT_CHANGE,
      item,
    });
  };
};

export const changeSelectedCardPosition = (item, positionDir) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SELECTED_CARD_POSITION,
      item,
      positionDir,
    });
  };
};

export const deselectProductsForPrint = (item) => {
  return (dispatch) => {
    dispatch({
      type: DESELECT_PRODUCTS_FOR_PRINT,
      item,
    });
  };
};

export const putInCart = (item) => {
  return (dispatch) => {
    dispatch({
      type: PUT_IN_CART,
      item,
    });
  };
};

export const removeFromCart = (item) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_FROM_CART,
      item,
    });
  };
};

export const loadAllProducts = () => {
  return (dispatch) => {
    dispatch({
      type: LOAD_ALL_PRODUCTS,
    });
  };
};

export const updateProductData = (params) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCT_DATA,
      params: params,
    });
  };
};

export const updateFilteredProducts = (params) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FILTERED_PRODUCTS,
      params: params,
    });
  };
};

export const clearSearchFilters = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEARCH_FILTERS,
    });
  };
};

export const selectAllFilteredItems = () => {
  return (dispatch) => {
    dispatch({
      type: ALL_SELECTIONS,
    });
  };
};

export const clearSelecitons = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SELECTIONS,
    });
  };
};

export const sortItems = (method) => {
  return (dispatch) => {
    dispatch({
      type: SORT_DATA,
      method: method,
    });
  };
};

export const getProductDataFromAPI = (type, code, custitem_ns_komida) => {
  return (dispatch) => {
    let url = PRODUCT_API_URL[type];
    if (code) {
      url += "&code=" + code;
    }

    if (custitem_ns_komida) {
      url += "&custitem_ns_komida=" + encodeURIComponent(custitem_ns_komida);
    }

    return axios({
      method: "get",
      url: url,
      timeout: 1000 * 120,
    })
      .then(function(response) {
        dispatch({
          type: GET_PRODUCT_DATA_FROM_API,
          allProducts: response.data,
          filterChildren: !!custitem_ns_komida,
        });
      })
      .catch(function(error) {
        // handle error
        console.log("getting product data from api failed", error);
      });
  };
};

export const GET_PRODUCT_DATA_FROM_API = "product/GET_PRODUCT_DATA_FROM_API";
export const LOAD_MORE_PRODUCTS = "product/LOAD_MORE_PRODUCTS";
export const LOAD_ALL_PRODUCTS = "product/LOAD_ALL_PRODUCTS";
export const UPDATE_FILTERED_PRODUCTS = "product/UPDATE_FILTERED_PRODUCTS";
export const UPDATE_PRODUCT_DATA = "product/UPDATE_PRODUCT_DATA";
export const CLEAR_SEARCH_FILTERS = "product/CLEAR_SEARCH_FILTERSS";
export const SORT_DATA = "product/SORT_DATA";
export const SELECT_PRODUCTS_FOR_PRINT = "product/SELECT_PRODUCTS_FOR_PRINT";
export const HANDLE_TEXT_CHANGE = "product/HANDLE_TEXT_CHANGE";
export const CHANGE_SELECTED_CARD_POSITION =
  "product/CHANGE_SELECTED_CARD_POSITION";
export const SELECT_BREAKPOINT_FOR_PRINT =
  "product/SELECT_BREAKPOINT_FOR_PRINT";
export const DESELECT_PRODUCTS_FOR_PRINT =
  "product/DESELECT_PRODUCTS_FOR_PRINT";
export const DESELECT_BREAKPOINT_FOR_PRINT =
  "product/DESELECT_BREAKPOINT_FOR_PRINT";
export const SELECT_SMALLER_TEXT = "product/SELECT_SMALLER_TEXT";
export const DESELECT_SMALLER_TEXT = "product/DESELECT_SMALLER_TEXT";
export const PUT_IN_CART = "product/PUT_IN_CART";
export const REMOVE_FROM_CART = "product/REMOVE_FROM_CART";
export const CLEAR_SELECTIONS = "product/CLEAR_SELECTIONS";
export const ALL_SELECTIONS = "product/ALL_SELECTIONS";
