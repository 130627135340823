export const chunks = (arr, size, shouldBreak) => {
  if (!Array.isArray(arr)) {
    throw new TypeError("Input should be Array");
  }

  if (typeof size !== "number") {
    throw new TypeError("Size should be a Number");
  }

  var result = [];
  for (var i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, size + i));
  }

  return result;
};

export const extractValue = (data, type) => {
  let values = data.map((v) => v[type]);
  let uniqueValues = values.filter((v, i) => values.indexOf(v) === i);

  return uniqueValues;
};

export const toHalfWidth = (strVal) => {
  // 半角変換
  var halfVal = strVal.replace(/[！-～]/g, (tmpStr) => {
    return String.fromCharCode(tmpStr.charCodeAt(0) - 0xfee0);
  });
  return halfVal;
};

export const toWholeWidth = (str) => {
  return str.replace(/[A-Za-z0-9]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
  });
};

export const flatten = (xs) =>
  xs.reduce(
    (acc, e) => (Array.isArray(e) ? acc.concat(flatten(e)) : acc.concat(e)),
    []
  );

export const queryCheck = (queryKey, queryValue) => {
  let url = window.location.href;
  if (url.indexOf("?" + queryKey + "=" + queryValue) !== -1) return true;
  else if (url.indexOf("&" + queryKey + "=" + queryValue) !== -1) return true;
  return false;
};

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  // name = name.replace(/[]/g, '\\$&');
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};
