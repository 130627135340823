import React from 'react';
import './subNavi.css';


//[TODO] this should be an container with a bunch of components
const SubNavi = ({ numOfItems, sortItemFn } ) => {

  let filterDataWithIESupport = (event, type)=>{
    let target = (event.currentTarget) ? event.currentTarget : event.srcElement;
    let value = target.value;
    sortItemFn(value);
  }
  return (
    <div className="header-subnav">
      <div className="container clearfix">
        <div className="subnav-result">
          <p className="subnav-result-text">検索結果: { numOfItems } </p>
        </div>
        <div className="subnav-sort">
          <label>並べ替え: </label>
          <select id="select-sort" className="selector-sort" onChange={ (e)=> filterDataWithIESupport(e)}>
            <option value="recommended">おすすめ商品</option>
            <option value="price-high">価格が高い順番</option>
            <option value="price-low">価格が安い順番</option>
            <option value="stock-high">在庫数が高い順番</option>
            <option value="stock-low">在庫数が低い順番</option>
          </select>
        </div>
      </div>
    </div>
  )
}
export default SubNavi
