import React from 'react'
import ReactModal from 'react-modal';
import guideline1 from "../../assets/guideline1.jpg"
import guideline2 from "../../assets/guideline2.jpg"
import './guideline.css';

const Guideline = ({modalOpen, closeFn})=>{
  return (
    <div className="guideline">
      <ReactModal
        isOpen={modalOpen}
        ariaHideApp={false}
        onRequestClose={closeFn}
        contentLabel="Guideline"
        className="modal-guideline"
        overlayClassName="modal-guideline-overlay"
      >
        <button className="btn-close btn" onClick={closeFn}><i className="fa fa-close"></i></button>
        <main className="container-fluid">
          <div className="explanation-header">
            <h2 className="guideline-title">[当サイトの使い方について]</h2>
            <p className="guideline-intro">初めてご訪問いただいた方にもご活用いただけるよう、当社カタログサイトの機能についてご紹介します。
            </p>
          </div>
          <div className="row section">
            <img className="img-guideline col-lg-8" alt="hint-1" src={guideline1}/>
            <div className="explanation text col-lg-4">
              <p> <span className="badge">1</span>商品名・品番で検索できます。</p>
              <p> <span className="badge">2</span>シーズンを選択できます。複数選択も可能です。
                シーズンによって出荷可能月が異なります。
                ・オール：通年出荷可能
                ・SS：2～8月出荷可能
                ・AW：9～1月出荷可能 </p>
              <p> <span className="badge">3</span>商品のカテゴリーを選択できます。</p>
              <p><span className="badge">4</span>上代の金額範囲を指定できます。</p>
              <p><span className="badge">5</span>指定した検索範囲の商品リストを印刷できます。</p>
              <p><span className="badge">6</span>指定した検索範囲の商品点数です。</p>
              <p><span className="badge">7</span>商品の表示順を並べ替えることができます。</p>
            </div>
          </div>
          <div className="row">
            <img className="img-guideline col-lg-8" alt="hint-2" src={guideline2}/>
            <div className="explanation text col-lg-4">
              <p> <span className="badge">8</span>商品詳細です。クリックすると商品画像を拡大できます。</p>
              <p> <span className="badge">9</span>商品画像の左上に表示されるボタンをクリックし、選択した商品をまとめて印刷できます。</p>
              <p> <span className="badge">10</span>選択した商品を解除することができます。</p>
            </div>
          </div>
        </main>
      </ReactModal>
    </div>
  )
}

export default Guideline
